.upload {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black
  }

.imgPreview {
  img {
    max-width: 100%;
    border: 2px solid black;
  }
}

.results-container {
  border: 2px solid black;
  margin: 2px;
}

.results-history {
  display: flex;
  align-items: center;

  ul {
    list-style: none;
  }

  .results-history-img {
    width: 45%;

    img {
      max-width: 90%;
    }
  }
  .results-history-barcode {
    flex-grow: 1;
  }
}
